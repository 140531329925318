<template>
  <div class="button-report dropdown">
    <div class="btn-group">
      <button
        class="btn btn-icon btn-icon-left"
        :class="{ [buttonClass]: true, loading: running }"
        @click="run"
        :disabled="disabled || running"
      >
        <slot
          v-bind:option="currentOption"
          v-bind:icon="values.icon"
          v-bind:label="values.label"
        >
          <fa-icon :icon="values.icon"></fa-icon>
          <span>{{ values.label }}</span>
          <span
            class="ml-1"
            v-if="hasOptions && currentOption"
          >({{ currentOption.text }})</span>
        </slot>
      </button>
      <template v-if="hasOptions && !disabled">
        <a
          class="btn dropdown-toggle"
          :class="buttonClass"
          tabindex="0"
        ><i class="icon icon-caret"></i></a>
        <ul class="menu">
          <li
            class="menu-item"
            v-for="item in values.options"
            :key="item.value"
            @click="setCurrentOption(item)"
          ><a class="c-hand">{{ item.text }}</a></li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';

export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'pdf',
    },
    route: {
      type: String,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    icon: {
      type: Array,
    },
    options: {
      type: Array,
    },
    buttonClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      running: false,
      currentOption: null,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    defaults() {
      switch (this.type.toLowerCase()) {
        case 'pdf':
          return {
            label: 'Imprimir',
            icon: ['fal', 'print'],
            options: [
              { value: 'a4', text: 'A4' },
              { value: 'a5', text: 'A5' },
            ],
            errorMessage: 'Ocorreu um erro durante o processo de impressão',
          };
        case 'sheet':
        case 'xlsx':
          return {
            label: 'Gerar planilha',
            icon: ['fal', 'file-excel'],
            options: null,
            errorMessage: 'Ocorreu um erro durante a geração da planilha',
          };
        case 'csv':
          return {
            label: 'Gerar CSV',
            icon: ['fal', 'file-csv'],
            options: null,
            errorMessage: 'Ocorreu um erro durante a geração do CSV',
          };
        default:
          return {
            label: 'Relatório',
            icon: ['fal', 'file'],
            options: null,
            errorMessage: 'Ocorreu um erro durante a geração do relatório',
          };
      }
    },
    values() {
      return {
        label: this.label || this.defaults.label,
        icon: this.icon || this.defaults.icon,
        options: typeof this.options === 'undefined'
          ? this.defaults.options
          : this.options || null,
        errorMessage: this.defaults.errorMessage,
      };
    },
    hasOptions() {
      return this.values.options
        && Array.isArray(this.values.options)
        && this.values.options.length > 0;
    },
    storageKey() {
      let { path } = this.$route;

      if (this.$route.params) {
        Object.keys(this.$route.params).forEach((key) => {
          path = path.replace(this.$route.params[key], key);
        });
      }

      return [
        'report',
        path,
        this.type,
      ].join('/').replace(/\/\//g, '/');
    },
  },
  methods: {
    async init() {
      if (this.hasOptions) {
        let option;

        const value = await localforage.getItem(this.storageKey).catch(() => null);
        if (value) {
          option = this.values.options.find(item => item.value === value);
        }

        if (!option) {
          [option] = this.values.options;
        }

        this.setCurrentOption(option);
      }
    },
    setCurrentOption(option) {
      this.currentOption = option;
      localforage.setItem(this.storageKey, option ? option.value : '');
    },
    async run() {
      if (!this.validator() || this.running) return;

      this.running = true;
      try {
        const type = this.type.toLowerCase();
        const format = this.currentOption ? `${type}:${this.currentOption.value}` : type;

        const params = {
          ...this.params,
          reportType: format,
          format,
        };

        if (type === 'pdf') {
          await this.$fileX.print(this.route, params);
        } else {
          await this.$fileX.download(this.route, params);
        }
      } catch (e) {
        const error = e || new Error();
        if (e.response?.data) {
          error.message = e.response.data.message || e.message;
          delete error.response;
        }

        error.message = `${this.values.errorMessage}.\n[${e.message}]`;
        this.$toast.error(error);
      } finally {
        this.running = false;
      }
    },
  },
};
</script>

<style lang="scss">
.button-report {
  vertical-align: middle;
  .menu {
    min-width: 100%;
    .menu-item {
      text-align: right;
    }
  }
}
</style>
