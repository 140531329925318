export function parseTemplate(template, data = {}) {
  let text = template;
  Object.keys(data).forEach((key) => {
    text = text.replace(new RegExp(`\\[${key}]`, 'g'), data[key]);
  });
  return text;
}

export function parsePhone(value) {
  if (!value) return null;
  return `55${value.replace(/\D/g, '')}`;
}

const URLS = Object.freeze({
  web: 'https://web.whatsapp.com/send?phone={{phone}}&text={{text}}',
  app: 'whatsapp://send?phone={{phone}}&text={{text}}',
  // wa_me: 'https://wa.me/{{phone}}?text={{text}}',
});

export function links(phone, params) {
  phone = parsePhone(phone);
  if (!phone) return null;

  const text = params.data
    ? parseTemplate(params.template, params.data)
    : params.template;
  const encodedText = encodeURIComponent(text);

  return Object.keys(URLS).reduce((acc, key) => {
    acc[key] = URLS[key]
      .replace('{{phone}}', phone)
      .replace('{{text}}', encodedText);
    return acc;
  }, {});
}

export function link(phone, template, data = null) {
  phone = parsePhone(phone);
  if (!phone) return null;

  const text = data ? parseTemplate(template, data) : template;

  return URLS.web
    .replace('{{phone}}', phone)
    .replace('{{text}}', encodeURIComponent(text));
}
