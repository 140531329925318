<template>
  <div class="chat-message-item tile" :class="{ reverse: isMe }">
    <div class="tile-icon" v-if="!isMe">
      <figure
        class="avatar"
        :data-initial="$avatarLetters(author.name)"
        :title="firstName"
      />
    </div>
    <div class="tile-content">
      <div class="chat-time"><small>{{ time }}</small></div>
      <div class="chat-text">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';

export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      today: moment().startOf('day'),
    };
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
    }),
    ...mapGetters({
      current: 'chatCurrent',
    }),
    isMe() {
      return this.item.authorId === this.user.id;
    },
    author() {
      if (this.isMe) {
        return { id: this.user.id, name: this.user.name };
      }
      return this.current.members.find(({ id }) => this.item.authorId === id);
    },
    firstName() {
      return this.author ? this.author.name.split(' ')[0] : '';
    },
    time() {
      const date = moment(this.item.date);

      return date.isSameOrAfter(moment().startOf('day'))
        ? date.format('HH:mm')
        : date.format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

.chat-message-item.tile {
  $radius: $layout-spacing-lg;
  .avatar {
    background-color: rgba($secondary-color, 0.2);
    font-weight: 400;
    color: $gray-color-700;
    height: 2rem;
    width: 2rem;
  }
  .tile-content {
    padding-right: $layout-spacing;
  }
  .chat-time {
    color: $gray-color;
    font-size: $font-size-sm;
  }
  .chat-text {
    background-color: $gray-color-light;
    border-radius: 0 $radius $radius $radius;
    display: inline-flex;
    margin-top: $border-width;
    max-width: 11.4rem;
    padding: $layout-spacing;
    word-break: break-word;
  }
  &.reverse {
    flex-direction: row-reverse;
    .tile-content {
      text-align: right;
      padding-right: 0;
    }
    .chat-text {
      background-color: $blue-color-200;
      text-align: right;
      border-radius: $radius 0 $radius $radius;
    }
  }
}
</style>
