import WhatsappWeb from '@/components/message/WhatsappWebModal.vue';

let VueComponent;
let component;

function close() {
  component.show = false;
  component.$destroy();
}

function open(phone, text, props = {}) {
  component = new VueComponent();

  Object.keys(props).forEach((key) => {
    component[key] = props[key];
  });

  component.show = true;
  component.phone = phone;
  component.text = text;
  component.$on('close', close);

  component.$mount();
}

export default function install(Vue) {
  VueComponent = Vue.extend(WhatsappWeb);

  Object.defineProperty(Vue.prototype, '$whatsappWeb', {
    get() {
      return { open, close };
    },
  });
}
