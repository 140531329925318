<template>
  <dx-modal
    :value="show"
    @input="close"
    title="Mensagem - WhatsApp Web"
  >
    <div class="form-group">
      <div class="input-group">
        <span class="input-group-addon"><fa-icon :icon="['fab', 'whatsapp']" /></span>
        <input
          type="text"
          class="form-input"
          v-model="form.phone"
          v-mask-phone.br
          :readonly="!editMode"
          placeholder="(00) 0 0000-0000"
        >
      </div>
    </div>

    <div class="form-group" v-if="editMode">
      <textarea
        id="whatsapp-web-text"
        class="form-input"
        v-model="form.text"
        placeholder="Texto da mensagem..."
      ></textarea>
    </div>
    <message-preview
      v-else
      channel="whatsapp"
      :body="form.text"
    />

    <template v-slot:footer>
      <button
        type="button"
        class="btn btn-info-outline btn-icon float-left tooltip"
        :data-tooltip="editMode ? 'Visualizar' : 'Editar'"
        @click="editMode = !editMode"
      ><fa-icon :icon="['fal', editMode ? 'eye' : 'pencil']" /></button>
      <a
        v-if="canSend"
        :href="links.app"
        class="btn btn-icon btn-icon-left btn-primary ml-2"
      ><fa-icon :icon="['fal', 'desktop']" /> WhatsApp Desktop</a>
      <a
        v-if="canSend"
        :href="links.web"
        target="_blank"
        class="btn btn-icon btn-icon-left btn-primary ml-2"
      ><fa-icon :icon="['fab', 'chrome']" /> WhatsApp Web</a>
      <button
        type="button"
        class="btn ml-2"
        @click="close"
      >Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { links } from '@/helpers/whatsapp';
import { clearNonNumbers } from '@/helpers/string';
import MessagePreview from '@/components/message/Preview.vue';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: null,
    },
  },
  components: {
    MessagePreview,
  },
  data() {
    return {
      form: this.blankForm(),
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    links() {
      return links(this.form.phone, {
        template: this.form.text,
      });
    },
    canSend() {
      const phone = clearNonNumbers(this.form.phone);
      return phone && phone.length >= 10 && !!this.links;
    },
  },
  methods: {
    init() {
      this.form.phone = this.phone;
      this.form.text = this.text;
      if (this.editMode === null && !this.form.text) {
        this.editMode = true;
      }
    },
    close() {
      this.form = this.blankForm();
      this.$emit('close');
    },
    blankForm() {
      return {
        phone: '',
        text: '',
        editMode: false,
      };
    },
  },
};
</script>

<style scoped lang="scss">
#whatsapp-web-text {
  field-sizing: content;
  max-height: 80vh;
  min-height: 12rem;
}
</style>
