<template>
  <div class="appointment-productions-report-page">
    <h6>Relatório de produtividade dos colaboradores</h6>
    <div class="columns form-group">
      <div class="column col-6 col-md-6 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-6 col-md-6 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
    </div>
    <div class="footer">
      <st-button-report
        class="ml-2"
        type="xlsx"
        button-class="btn-gray"
        route="/appointments/print-productions"
        :params="reportParams"
        :validator="reportValidator"
      ></st-button-report>
      <st-button-report
        class="ml-2"
        type="pdf"
        button-class="btn-info"
        route="/appointments/print-productions"
        :options="[]"
        :params="reportParams"
        :validator="reportValidator"
      ></st-button-report>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      filters: {
        startDate: '',
        endDate: '',
      },
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.filters.startDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filters.endDate = moment()
      .endOf('month')
      .format('YYYY-MM-DD');
  },
  computed: {
    reportParams() {
      return {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
      };
    },
  },
  methods: {
    reportValidator() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }

      const duration = moment.duration(moment(this.filters.endDate)
        .diff(moment(this.filters.startDate))).asMonths();

      if (duration > 3) {
        this.$toast.show('Período limite (3 meses)', { type: 'error' });
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import '~assets/scss/mixins';
  .appointment-productions-report-page {
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
