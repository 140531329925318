import Dialog from 'src/components/modal/Dialog.vue';

let VueComponent;
let component;

function close() {
  component.show = false;
  component.$destroy();
}

function show(title, params = {}) {
  const localParams = Object.assign(
    {
      type: 'success', // success|danger|neutral
      closable: true,
      html: null,
      buttons: null,
    },
    params,
  );

  component = new VueComponent();

  component.show = true;
  component.title = title;
  component.type = localParams.type;
  component.closable = localParams.closable;
  component.html = localParams.html;
  component.buttons = localParams.buttons;
  component.$on('close', close);

  component.$mount();
}

export default function install(Vue) {
  VueComponent = Vue.extend(Dialog);

  Object.defineProperty(Vue.prototype, '$dialog', {
    get() {
      return { show, close };
    },
  });
}
