import Vue from 'vue';
import Http from './http';
import router from '../router/index';
import AvatarLetters from './avatar-letters';
import Bus from './bus';
import Cep from './cep';
import Dialog from './dialog';
import File from './file';
import FileX from './file/fileX';
import Message from './message';
import Notification from './notification';
import Storage from './storage';
import TimeDiff from './time/time-diff';
import Toast from './toast';

export default () => {
  Vue.use(Http, { router });
  Vue.use(AvatarLetters);
  Vue.use(Bus);
  Vue.use(Cep);
  Vue.use(Dialog);
  Vue.use(File);
  Vue.use(FileX);
  Vue.use(Message);
  Vue.use(Notification);
  Vue.use(Storage);
  Vue.use(TimeDiff);
  Vue.use(Toast);
};
